.header-logo-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	div.header-logo {
		height: 32px;
		width: 32px;
		cursor: pointer;
		background-repeat: no-repeat;
	}
}
