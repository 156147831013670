@import './theme-dark-map';
@import './theme-light-map';

@mixin themable($theme-name, $theme-map) {
	body .#{$theme-name} {
		// Loop through each of the keys (sections)
		@each $section, $map in $theme-map {
			@if ($section == body) {
				background-color: map-get($map, bg);

				h1 {
					font-family: map-get($map, headline-font-family);
					font-weight: map-get($map, headline-font-weight);
					color: map-get($map, text-color);
					font-size: 3rem;
					line-height: 0.95;
				}

				h2 {
					font-family: map-get($map, headline-font-family);
					font-weight: map-get($map, headline-font-weight);
					color: map-get($map, text-color);
					font-size: 2.34rem;
				}

				h3 {
					font-family: map-get($map, headline-font-family);
					font-weight: map-get($map, headline-font-weight);
					color: map-get($map, text-color);
					font-size: 1.67rem;
				}

				h4 {
					font-family: map-get($map, headline-font-family);
					font-weight: map-get($map, headline-font-weight);
					color: map-get($map, text-color);
					font-size: 1rem;
				}

				h5 {
					font-family: map-get($map, headline-font-family);
					font-weight: map-get($map, headline-font-weight);
					color: map-get($map, text-color);
					font-size: 0.83rem;
				}

				h6 {
					font-family: map-get($map, headline-font-family);
					font-weight: map-get($map, headline-font-weight);
					color: map-get($map, text-color);
					font-size: 0.67rem;
				}

				p,
				span,
				input,
				textarea {
					font-family: map-get($map, paragraph-font-family);
					font-weight: map-get($map, paragraph-font-weight);
					color: map-get($map, text-color);
					font-size: 1rem;
					line-height: 1.33;
				}

				a {
					text-decoration: none;
					color: map-get($map, text-color);
					white-space: nowrap;
					&:hover {
						color: map-get($map, link-hover-color);
					}
				}
			} @else {
				#{$section} {
					background-color: map-get($map, bg);

					@if ($section == nav) {
						border-bottom: 1px solid map-get($map, border-bottom);
						.theme-switcher {
							background-color: map-get($map, theme-switcher);
						}
						// Mobile
						.mobile-menu-header,
						.mobile-menu-inner-content {
							background-color: map-get($map, mobile-menu-header);
						}

						.menu-button-animation[data-menu-open='true'] span {
							background-color: map-get($map, menu-button-transparent);
						}
						.menu-button span,
						.menu-button span:before,
						.menu-button span:after {
							background-color: map-get($map, menu-button-span);
						}

						.text-link-list-item .active h2,
						.text-link .active h4 {
							color: map-get($map, is-active);
						}

						.header-logo {
							background-image: map-get($map, header-logo);
						}
						.social-media-links,
						.mobile-social-links {
							.github {
								background-image: map-get($map, social-github);
							}

							.linkedin {
								background-image: map-get($map, social-linkedin);
							}

							.codepen {
								background-image: map-get($map, social-codepen);
							}
						}
					}

					@if ($section == header) {
						.hero .hero-rip {
							// background-image:
						}
					}

					@if ($section == footer) {
						.hero .hero-rip {
							// background-image:
						}
						.github {
							background-image: map-get($map, social-github);
						}
						.linkedin {
							background-image: map-get($map, social-linkedin);
						}
						.codepen {
							background-image: map-get($map, social-codepen);
						}
						.behance {
							background-image: map-get($map, social-behance);
							background-repeat: no-repeat;
							margin-top: 6px;
						}
						.dribbble {
							background-image: map-get($map, social-dribbble);
						}
						.instagram {
							background-image: map-get($map, social-instagram);
						}
						.send {
							background-color: map-get($map, action-button-bg);
						}
					}
				}
			}
		}
	}
}

@include themable(theme-dark, $theme-dark);
@include themable(theme-light, $theme-light);
