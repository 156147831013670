$font-size: 280px;
$bg-color: #ffffff;
$text-color: #f2f2f2;
$message-color: #0d0d0d;

.error-page {
	font-family: yorkten-slab-extended, sans-serif;
	overflow-x: hidden;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	background: $bg-color;
	line-height: 1.7;
	color: $message-color;
	transform: skewY(-5deg);

	body {
		background: $bg-color;
	}

	.bg-text-container {
		transform: translateX(-50%);
		left: 50%;
		position: absolute;
		z-index: -999;
	}

	@keyframes text-scrolling {
		0% {
			transform: translate3d(-100%, 0, 0);
		}
		100% {
			transform: translate3d(0%, 0, 0);
		}
	}

	.animate-text {
		animation: text-scrolling 20s linear infinite;
		will-change: transform;
		display: block;
		position: relative;
		white-space: nowrap;

		&.left {
			animation-direction: reverse;
		}
	}

	span {
		font-size: $font-size;
		color: transparent;
		-webkit-text-stroke: 2px $text-color;
		text-transform: uppercase;
		display: inline-block;
		line-height: 0.75;
		min-width: auto;
		font-weight: 800;
	}

	.container {
		padding: 30px;
		max-width: 1000px;
		width: 100%;
		margin: 0 auto;

		.col {
			max-width: 600px;
			margin: 0;
		}
	}

	h1 {
		font-size: 90px;
		margin: 0;
		color: $message-color;
	}

	p {
		font-family: proxima-nova, sans-serif;
		font-weight: 500;
		font-size: 18px;
		margin: 0;
	}

	a {
		color: #7ad860;
		text-decoration: none;
	}

	a:visited {
		color: #7ad860;
		text-decoration: none;
	}
}
