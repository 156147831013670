@import '../scss-partials/mediaqueries';

footer {
	padding-bottom: 2%;

	.hero-rip {
		// background-image: url(../assets/rips-upsidedown.png);
		background-position: top;
		background-repeat: repeat-x;
		background-size: contain;
		top: -0.1rem;
		height: 9.2rem;
		left: 0;
		right: 0;
		z-index: 2;
	}

	.footer-content {
		display: flex;
		align-items: stretch;
		padding-left: 5%;
		padding-right: 5%;

		.column-left {
			order: 1;

			p {
				margin-top: 2rem;
				margin-bottom: 2rem;
			}

			.social-media-links {
				display: flex;
				flex-direction: row;
				margin-top: 2rem;
				margin-bottom: 2rem;

				a {
					width: 32px;
					&:not(:last-child) {
						margin-right: 32px;
					}

					div {
						height: 32px;
						width: 32px;
						cursor: pointer;
					}
				}
			}
		}

		.column-right {
			order: 2;
		}
	}

	.copyright {
		position: bottom;
		margin-top: 4%;
		padding-left: 5%;
		padding-right: 5%;
	}

	@media only screen and (max-width: $media-large2) {
		.footer-content {
			flex-direction: column;
			justify-content: center;

			.column-left {
				text-align: center;

				.social-media-links {
					justify-content: center;
				}
			}
		}
	}

	@media only screen and (min-width: $media-large2) {
		.footer-content {
			flex-direction: row;
			justify-content: space-between;

			.column-left {
				width: 50%;
				padding-right: 4%;
			}

			.column-right {
				width: 50%;
				margin-top: 3.8rem;
			}
		}
	}
}
