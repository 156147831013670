.menu-button {
	position: relative;
	display: inline-block;
	width: 32px;
	height: 24px;
	margin: 25px;
}
.menu-button span {
	margin: 0 auto;
	position: relative;
	top: 10px;
}
.menu-button span:before,
.menu-button span:after {
	position: absolute;
	content: '';
}
.menu-button span,
.menu-button span:before,
.menu-button span:after {
	width: 32px;
	height: 4px;
	display: block;
}
.menu-button span:before {
	margin-top: -10px;
}
.menu-button span:after {
	margin-top: 10px;
}

.menu-button-animation span {
	transition-duration: 0s;
	transition-delay: 0.2s;

	-webkit-transition-duration: 0s;
	-webkit-transition-delay: 0.2s;
}
.menu-button-animation[data-menu-open='true'] span {
	transition-delay: 0.2s;

	-webkit-transition-delay: 0.2s;
}
.menu-button-animation span:before {
	transition-property: margin, transform;
	transition-duration: 0.2s;
	transition-delay: 0.2s, 0s;

	-webkit-transition-property: margin, -webkit-transform;
	-webkit-transition-duration: 0.2s;
	-webkit-transition-delay: 0.2s, 0s;
}
.menu-button-animation[data-menu-open='true'] span:before {
	-webkit-transform: rotate(45deg);
	-webkit-transition-delay: 0s, 0.2s;

	margin-top: 0;
	transform: rotate(45deg);
	transition-delay: 0s, 0.2s;
}
.menu-button-animation span:after {
	transition-property: margin, transform;
	transition-duration: 0.2s;
	transition-delay: 0.2s, 0s;

	-webkit-transition-property: margin, -webkit-transform;
	-webkit-transition-duration: 0.2s;
	-webkit-transition-delay: 0.2s, 0s;
}
.menu-button-animation[data-menu-open='true'] span:after {
	margin-top: 0;
	transform: rotate(-45deg);
	transition-delay: 0s, 0.2s;

	-webkit-transform: rotate(-45deg);
	-webkit-transition-delay: 0s, 0.2s;
}
