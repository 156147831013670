@import '../scss-partials/mediaqueries';

nav {
	position: -webkit-sticky; /* for Safari */
	position: sticky;
	top: 0px;
	display: flex;
	align-self: flex-start;
	z-index: 97;

	.theme-switcher {
		border-radius: 50%;
		border: none;
		width: 24px;
		height: 24px;
	}

	@media only screen and (max-width: $media-medium) {
		&.desktop-navigation {
			display: none;
		}

		&.mobile-navigation {
			width: 100%;
			height: 74px;
			display: flex;
			flex-direction: column;

			.mobile-menu-header {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: 74px;
				padding-left: 25px;
				z-index: 99;
			}
		}
	}

	@media only screen and (min-width: $media-medium) {
		&.mobile-navigation {
			display: none;
		}

		&.desktop-navigation {
			display: flex;
			height: 6rem;

			.theme-switcher {
				margin-left: 16px;
			}

			@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
				-webkit-backdrop-filter: saturate(85%) blur(8px);
				backdrop-filter: saturate(85%) blur(8px);
			}

			div {
				padding-left: 6%;
				padding-right: 6%;
			}

			.text-link {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				width: 100%;
				text-transform: capitalize;

				a {
					margin-right: 1rem;
					margin-left: 1rem;
				}

				.social-media-links {
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
					text-transform: capitalize;

					div {
						height: 24px;
						width: 24px;
						cursor: pointer;
						background-repeat: no-repeat;
					}
				}
			}
		}
	}
}
