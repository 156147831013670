@import '../scss-partials/mediaqueries';

form {
	display: flex;
	flex-direction: column;
	width: 100%;

	textarea,
	input {
		padding: 8px;
		margin-bottom: 8px;
		border-radius: 2px;
		border: none;
	}

	.send {
		align-self: flex-end;
		cursor: pointer;

		@media only screen and (max-width: $media-large2) {
			width: 100%;
		}

		@media only screen and (min-width: $media-large2) {
			width: 25%;
		}
	}
}
