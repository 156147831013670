@import './scss-partials/theme';

// A few defaults
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	width: 100vw;
	height: 100vh;

	.display-none {
		display: none;
	}

	#root {
		width: 100vw;
		height: 100vh;
	}
}
