@import '../scss-partials/mediaqueries';

header {
	width: 100%;
	.hero {
		padding-bottom: 8rem;
		padding-top: 4rem;
		position: relative;

		.introduction {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;

			.intro-text {
				flex-direction: column;

				p {
					margin-top: 1rem;
				}
			}
		}

		.hero-rip {
			background-position: bottom;
			background-repeat: repeat-x;
			background-size: contain;
			bottom: -0.1rem;
			height: 9.2rem;
			left: 0;
			position: absolute;
			right: 0;
			z-index: 2;
		}
	}

	@media only screen and (max-width: $media-medium) {
		.introduction {
			flex-direction: column;

			.person {
				order: 1;
			}

			.intro-text {
				order: 2;
				padding-left: 10%;
				padding-right: 10%;
			}
		}
	}

	@media only screen and (min-width: $media-medium) and (max-width: $media-medium2) {
		.introduction {
			flex-direction: row;

			.intro-text {
				padding-left: 10%;
				padding-right: 4%;
			}

			.person {
				padding-right: 10%;
			}
		}
	}

	@media only screen and (min-width: $media-medium2) and (max-width: $media-large) {
		.introduction {
			flex-direction: row;

			.intro-text {
				padding-left: 10%;
				padding-right: 4%;
			}

			.person {
				padding-right: 10%;
			}
		}
	}

	@media only screen and (min-width: $media-large) and (max-width: $media-xlarge2) {
		.hero {
			@media only screen and (min-height: $media-medium) {
				min-height: calc(100vh - 7rem);
				height: calc(100vh - 7rem);
			}

			.introduction {
				padding-left: 10%;
				padding-right: 10%;
			}
		}
	}

	@media only screen and (min-width: $media-xlarge2) {
		.hero {
			min-height: calc(100vh - 7rem);
			height: calc(100vh - 7rem);

			.introduction {
				padding-left: 20%;
				padding-right: 20%;
			}
		}
	}
}
